<template>
  <div class="page_container">
    <h-banner :bg='banner'>
      <div slot="content">
        <div style="color:#fff;margin-top:80px">
          <h-title
            class="animate__animated animate__backInLeft"
            msg="聚焦工业领域，以流程行业中的化工行业为战略核心业务，兼顾离散行业客户"
          ></h-title>
        </div>
      </div>
    </h-banner>

    <div v-if="!$store.state.isPhone">
      <!-- <div class="page1">
        <div class="">
          <el-row>
            <el-col :span="12">
              <div class="page1-r">
                <div class="title">中国万达集团天弘化学MES项目</div>
                <div class="desc">按照中国万达集团“智能工厂”建设的总体规划，以天弘化学为试点，搭建统一的生产运行管理系统和实时数据平台，梳理和优化业务流程，实现生产业务对计划、调度、工艺、执行、统计、分析全过程的闭环管理，实现系统功能对生产、能耗、计量、质量、安环、设备、仓储等领域的基本覆盖，实现生产统计业务“班跟踪、日平衡、月结算”，充分发挥MES及实时数据库系统在“智能工厂” 中的核心作用。</div>
              </div>
            </el-col>
            <el-col :span="12">
              <el-image
                fit="cover"
                :src="banner2"
                class="image1"
              ></el-image>
            </el-col>
          </el-row>
        </div>
      </div> -->
      <!-- <div class="page_bg">
        <el-row>
          <el-col
            :span="12"
            style="background: #C5E3FF;"
          >
            <el-image
              fit="cover"
              :src="bg"
            ></el-image>
          </el-col>
          <el-col :span="12"></el-col>
        </el-row>
      </div> -->
<!--      <div class="page2 page-grey">-->
<!--        <div class="">-->
<!--          <el-row>-->
<!--            <el-col :span="12">-->
<!--              <el-image-->
<!--                fit="cover"-->
<!--                :src="banner3"-->
<!--                class="image2"-->
<!--                style="margin-top: 30px;"-->
<!--              ></el-image>-->
<!--            </el-col>-->
<!--            <el-col :span="12">-->
<!--              <div class="page1-r">-->
<!--                <div class="title">中海油气电集团生产运营协调管理系统</div>-->
<!--                <div class="desc">依据中海油集团公司“十三五”信息化以实现基础管理精细化、生产运营智能化、产业协同一体化、集团管控科学化的规划纲要，气电集团明确提出了“十三五”生产管理层数据采集自动化、过程运行可视化、生产管理数字化、智能化的信息化建设目标。通过建立覆盖产、供、储、销各环节的生产运营协调信息系统，以“四确保，一减少”为目标（确保数据自动化、准确性、系统性、全面性；减少人为误差），全面系统做好生产运营数据的综合分析，为集团生产经营提供决策依据。</div>-->
<!--              </div>-->
<!--            </el-col>-->
<!--          </el-row>-->
<!--        </div>-->
<!--      </div>-->
      <div class="page1">
        <div class="">
          <el-row>
            <el-col :span="12">
              <!-- <span class="al">典型案例</span> -->
              <div class="page1-r">
                <div class="title">内蒙古鄂尔多斯电冶集团MES项目</div>
                <div class="desc"> 针对氯碱生产管理特点，为鄂尔多斯电冶集团打造一套专属的MES生产管理系统，通过信息化带动工业化，走出一条科技含量高，经济效益好，资源消耗低，环境污染少，人力资源优势得到充分发挥的新型工业化路子，提高了企业对市场的影响能力，是企业在市场竞争中立于不败之地的必然选择。</div>
              </div>
            </el-col>
            <el-col :span="12">
              <el-image
                fit="cover"
                :src="banner4"
                class="image1"
              ></el-image>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="page_bg">
        <el-row>
          <el-col
            :span="12"
            style="background: #C5E3FF;"
          >
            <el-image
              fit="cover"
              :src="bg"
            ></el-image>
          </el-col>
          <el-col :span="12"></el-col>
        </el-row>
      </div>
      <div class="page2 page-grey">
        <div class="">
          <el-row>
            <el-col :span="12">
              <el-image
                fit="cover"
                :src="banner5"
                class="image2"
              ></el-image>
            </el-col>
            <el-col :span="12">
              <div class="page1-r">
                <div class="title">中石油西北销售油库安全运行管理系统</div>
                <div class="desc">以安全生产为核心，依托油库控制网络和信息网络一体化构建，集成油库的工艺、消防、安防、设备等的现场实时过程数据，打通系统壁垒，通过“一张图”形式，将工艺、消防、安防报警进行统一展示；同时，借助专业的大数据建模和分析技术，对油库进行运行监控、安全预警、操作规范性评价和数据分析应用，实现对油库安全生产运行的实时监控和智能化管理。</div>
              </div>
              <!-- <span class="al">典型案例</span> -->
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="page4">
        <div class="clearfix page-container">
          <div class="footer-body">
            <!-- <span class="title">中智软创</span>
            <span class="desc">石化行业智慧管控解决方案专家、顶级过程工业集成优化解决方案、国内领先的工业软件提供商</span> -->
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="p-page">
        <div
          class=""
          v-for="(item,i) in list"
          :key="item.title"
          :class="['page-item',{'page-grey':(i+1)%2==0}]"
        >
          <el-row>
            <el-col :span="24">
              <!-- <span class="al">典型案例</span> -->
              <div class="page1-r">
                <div class="title">{{item.title}}</div>
                <div class="desc">{{item.desc}}</div>
              </div>
            </el-col>
            <el-col :span="24">
              <el-image
                fit="cover"
                :src="item.img"
                class="image1"
              ></el-image>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cloud",
  data() {
    return {
      banner: require("../../assets/cloud/c-banner.png"),
      banner2: require("../../assets/cloud/c-img1.png"),
      banner3: require("../../assets/cloud/c-img2.png"),
      banner4: require("../../assets/cloud/c-img3.png"),
      banner5: require("../../assets/cloud/c-img4.png"),
      bg: require("../../assets/cloud/c-bg.png"),
      list: [
        // { img:require("../../assets/cloud/c-img1.png"),title: '中国万达集团天弘化学MES项目', desc: '按照中国万达集团“智能工厂”建设的总体规划，以天弘化学为试点，搭建统一的生产运行管理系统和实时数据平台，梳理和优化业务流程，实现生产业务对计划、调度、工艺、执行、统计、分析全过程的闭环管理，实现系统功能对生产、能耗、计量、质量、安环、设备、仓储等领域的基本覆盖，实现生产统计业务“班跟踪、日平衡、月结算”，充分发挥MES及实时数据库系统在“智能工厂” 中的核心作用。' },
        // { img:require("../../assets/cloud/c-img2.png"),title: '中海油气电集团生产运营协调管理系统', desc: '依据中海油集团公司“十三五”信息化以实现基础管理精细化、生产运营智能化、产业协同一体化、集团管控科学化的规划纲要，气电集团明确提出了“十三五”生产管理层数据采集自动化、过程运行可视化、生产管理数字化、智能化的信息化建设目标。通过建立覆盖产、供、储、销各环节的生产运营协调信息系统，以“四确保，一减少”为目标（确保数据自动化、准确性、系统性、全面性；减少人为误差），全面系统做好生产运营数据的综合分析，为集团生产经营提供决策依据。' },
        { img:require("../../assets/cloud/c-img3.png"),title: '内蒙古鄂尔多斯电冶集团MES项目', desc: '针对氯碱生产管理特点，为鄂尔多斯电冶集团打造一套专属的MES生产管理系统，通过信息化带动工业化，走出一条科技含量高，经济效益好，资源消耗低，环境污染少，人力资源优势得到充分发挥的新型工业化路子，提高了企业对市场的影响能力，是企业在市场竞争中立于不败之地的必然选择。' },
        { img:require("../../assets/cloud/c-img4.png"),title: '中石油西北销售油库安全运行管理系统', desc: '以安全生产为核心，依托油库控制网络和信息网络一体化构建，集成油库的工艺、消防、安防、设备等的现场实时过程数据，打通系统壁垒，通过“一张图”形式，将工艺、消防、安防报警进行统一展示；同时，借助专业的大数据建模和分析技术，对油库进行运行监控、安全预警、操作规范性评价和数据分析应用，实现对油库安全生产运行的实时监控和智能化管理。' },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 980px) {
  .image1 {
    margin-bottom: -80px;
    margin-top: 50px;
    height: 400px;
    box-shadow: 50px 50px 100px 0px rgba(0, 0, 0, 0.07);
  }
}
@media only screen and (min-width: 320px) and (max-width: 980px) {
  .image1 {
    height: 200px;
    box-shadow: 50px 50px 100px 0px rgba(0, 0, 0, 0.07);
  }
}
.p-page {
  .page-item {
    padding: 35px;
    .page1-r {
      .title {
        font-size: 1.4em;
        line-height: 2em;
      }
      .desc {
        font-size: 0.825em;
        line-height: 1.5em;
        margin-bottom: 20px;
      }
    }
  }
}

.page_container {
  background-color: #fff;
  .banner-desc {
    text-align: center;
    color: #fff;
  }
  .banner-bnt {
    width: 104px;
    height: 26px;
    background: #264bf8;
    border-radius: 12px;
    text-align: center;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 26px;
    margin-top: 25px;
    cursor: pointer;
  }
  .nav {
    .nav-container {
      display: flex;
      margin-left: 15%;
      margin-right: 15%;
      padding: 15px 25px;
      background-color: #fff;
      border-radius: 10px;
      margin-top: -45px;
      box-shadow: 50px 50px 100px 0px rgba(0, 0, 0, 0.07);
      .nav-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        > p {
          font-size: 0.825em;
          font-weight: 500;
          color: #333333;
          line-height: 2.2em;
        }
      }
    }
  }
  .pro {
    margin-left: auto;
    margin-right: auto;
    min-width: 1080px;
  }
  .image2 {
    margin-bottom: 50px;
    margin-top: -80px;
    height: 400px;
    box-shadow: 50px 50px 100px 0px rgba(0, 0, 0, 0.07);
  }
  .al {
    display: inline-block;
    transform: rotate(90deg);
    -ms-transform: rotate(90deg); /* Internet Explorer 9*/
    -moz-transform: rotate(90deg); /* Firefox */
    -webkit-transform: rotate(90deg); /* Safari 和 Chrome */
    -o-transform: rotate(90deg); /* Opera */
    font-size: 1.25em;
    font-weight: 500;
    color: #264bf8;
    transform-origin: left top;
    padding-left: 140px;
    width: 100px;
    margin-left: 100px;
  }
  .al2 {
    display: inline-block;
    transform: rotate(90deg);
    -ms-transform: rotate(90deg); /* Internet Explorer 9*/
    -moz-transform: rotate(90deg); /* Firefox */
    -webkit-transform: rotate(90deg); /* Safari 和 Chrome */
    -o-transform: rotate(90deg); /* Opera */
    font-size: 1.25em;
    font-weight: 500;
    color: #264bf8;
    transform-origin: left top;
    padding-left: 140px;
    width: 100px;
    margin-right: 100px;
  }
  .page1 {
    background: url("../../assets/about/a-a.png") no-repeat 50% fixed;
    .page-container {
      height: 450px;
    }

    .page1-r {
      padding: 50px;
      margin-left: 140px;
      border-left: 1px solid #ebebeb;
      height: 300px;
      .title {
        font-size: 1.625em;
        font-weight: bold;
        color: #0a0b18;
        line-height: 45px;
      }
      .desc {
        font-size: 1em;
        color: #575757;
        line-height: 24px;
        margin-top: 25px;
      }
    }
  }

  .page2 {
    .page-container {
      height: 450px;
    }
    .page1-r {
      padding: 50px;
      margin-right: 140px;
      border-right: 1px solid #ebebeb;
      height: 300px;
      .title {
        font-size: 1.625em;
        font-weight: bold;
        color: #0a0b18;
        line-height: 45px;
      }
      .desc {
        font-size: 1em;
        color: #575757;
        line-height: 24px;
        margin-top: 25px;
      }
    }
  }

  .page3 {
    padding-top: 65px;
    .page3-l {
      display: flex;
      flex-direction: row-reverse;
      background: url("../../assets/cloud/c-banner4.png") no-repeat;
      background-size: cover;
      .l-content {
        padding: 125px 35px;
        .title {
          font-size: 1.25em;
          font-weight: 400;
          color: #494949;
          line-height: 85px;
        }
        .c-title {
          font-size: 1.5em;
          font-weight: 500;
          color: #333333;
          line-height: 37px;
        }
        .c-desc {
          font-size: 0.825em;
          font-weight: 400;
          color: #7b7b7b;
          line-height: 22px;
        }
        .c-more {
          color: #264bf8;
          font-size: 0.825em;
          font-weight: 400;
          line-height: 30px;
        }
      }
    }
    .page3-r {
      padding: 45px 65px;
      .title {
        .title-cn {
          font-weight: 500;
          color: #494949;
          line-height: 37px;
          font-size: 1.25em;
        }
      }
      .content {
        display: flex;
        .content-num {
          > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 30px;
            margin-right: 40px;
            span {
              text-align: center;
              font-size: 1.825em;
              color: #264bf8;
              line-height: 30px;
            }
            div {
              font-size: 0.825em;
              font-weight: 400;
              color: #8e8e8e;
              line-height: 45px;
            }
          }
        }
      }
      .other {
        font-size: 0.825em;
        font-weight: 500;
        color: #264bf8;
        line-height: 25px;
        margin: 15px 0;
        cursor: pointer;
        display: inline-block;
        &:hover {
          -webkit-animation-name: heartBeat;
          animation-name: heartBeat;
        }
        i {
          margin: 0 8px;
        }
      }
      .step {
        width: 410px;
        height: 25px;
        margin: 15px 0;
        padding-left: 18px;
        background-image: url("../../assets/cloud/c-step.png");
        background-size: 100%;
        display: inline-block;
        ul {
          display: flex;
          li {
            display: inline-block;
            margin-right: 18px;
            margin-left: 5px;
            font-size: 13px;
            font-weight: 700;
            line-height: 25px;
            color: #0b1d30;
            vertical-align: top;
            list-style: none;
          }
        }
      }
    }
  }

  .page4 {
    width: 100%;
    height: 210px;
    background-image: url("../../assets/cloud/c-banner5.png");
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: 1920px;
    .page-container {
      .footer-body {
        width: 1200px;
        margin: 0 auto;
        padding-top: 100px;
        .title {
          font-size: 2em;
          font-weight: 500;
          color: #ffffff;
          line-height: 45px;
        }
        .desc {
          font-size: 1.25em;
          font-weight: 500;
          color: #ffffff;
          line-height: 33px;
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
